h2 {
  color: #394d7a;
  font-family: "Playfair_Display_Semibold";
  font-size: 2.6em;
  font-weight: normal;
  padding-top: 10px;
}

p {
  color: #243356;
}

h3 {
  font-weight: normal;
  color: #243356
}

#App .navigationButton{
  background-color: white;
}
#App .navigationButton:active{
  background-color: #c6d0da;
}
#App .navigationButton:hover{
  background-color: rgba(0,0,0,.05);
}


