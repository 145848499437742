#pictureWithDescriptionContainer #description {
  width:70%;
}
#pictureWithDescriptionContainer .sectionContainer {
  align-items: center;
}


#aboutMePage .HeartIcon {
  font-size: 1em;
  color: #6f7491;
}


#imageContainer {
  width:30%;
  height:16em;
  margin-right: 2em;

}

#pictureOfJessie {
  height:100%;
  width:auto;
}

#pieSection {
  background-color: #e6e6ee;
}


#conferencesSection {
  background-color: #e6e6ee;
  min-height: 20rem;
}
#pieSection .sectionContainer{
  height:20rem;
}

#pieSection .labels.last{
  justify-content: flex-end;
}
#randomFactSection .sectionContainer{
  align-items: center;
}

#randomFactSection ul{
  color: #243356;
  list-style: none;
  padding-inline-start: 2rem;

}

#randomFactSection li::before {
  content: "\f004";
  font-family: Icons;
  font-size: .8em;
  color: #6f7491;
  padding-right: 1em;
}

.sectionContainer{
  max-width: 70rem;
  padding: 2rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

#pieSection .column {
  display: flex;
}

#pieSection ul{
  width: fit-content;
  list-style: none;
  padding-inline-start: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#pieSection li {
  font-size: large;
  color: #243356;
}

#pieSection li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

#pieSection li#mathematician::before{
  color: #365bb7;
}
#pieSection li#dataScientist::before{
  color: #fdb50b;
}
#pieSection li#dataEngineer::before{
  color: #AAAAAA;
}
#pieSection li#programmer::before{
  color: #e36725;
}

#conferencesSection span {
  display: block;
  padding-left: 12rem;
}
#conferencesSection p{
  padding-left: 2rem;
}
#conferencesSection .sectionContainer {
  display: block;
}
.longDash{
  display: inline-block;
  margin: .2em 0;
  width: 1.2em;
}


@media (max-width: 700px) {
  #pictureWithDescriptionContainer .sectionContainer {
    flex-direction: column;
  }
  #pictureWithDescriptionContainer #imageContainer {
    width: unset;
    order: 1;
  }
  #pictureWithDescriptionContainer #description {
    width:100%;
    margin-bottom: 2em;
  }

  #randomFactSection .catImage {
    display: none;
  }

  #conferencesSection span {
    padding-left: 0;
  }

  #pieSection .sectionContainer{
    flex-direction: column;
    height: unset;
  }
  #pieSection .pieChart{
    height: 15rem;
  }
  #pieSection .labels{
    margin-bottom: 0;
  }

  #pieSection .labels ul{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  #pieSection .labels.last{
    justify-content: unset;
  }

}