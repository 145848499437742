.indented {
  margin-left: 10vw;
  margin-right: 10vw;
}

.indented2 {
  margin-left: 6vw;
  margin-right: 6vw;
}

#sendButtonContainer {
  display:flex;
  justify-content: flex-end;
}

#myEmail {
  padding-left: 4em;
  font-style: italic;
  font-weight: lighter;
  letter-spacing:.1em;
}

#linkedInIcon{
  width: 4rem;
}

#contactMeHeaderImage {
  width: 100vw;
  max-height: 30vh;
  padding-bottom: .15em;
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 70rem;
  padding: 2rem;
}
.column {
  width: 45%;
  margin-bottom: 2rem;
}

p.lessMargin {
  margin: 0em;
  margin-bottom: 0.2em;
}

#SocialMediaIcons {
  padding-left: 4em;
}




.ui.form .field>label {
  color: #243356
}

#contactMePage #sendButton {
  margin: 0;
  margin-top: 3px;
  padding: 0.5em 1.2em;
  font-weight: normal;
  color: #243356;
  background-color: #c6d0da;
}

#contactMePage .HeartIcon {
  font-size: 0.8em;
  color: #6f7491;
}

.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50vw;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 2rem 2rem;
  border: none;
  border-radius: 10px;
  font-weight: normal;
  color: #243356;

}

#SuccessNotification {
  background-color: #DDFFCC;
}
#FailureNotification {
  background-color: #ffd6da;
}
.notification.hidden{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1.5s, opacity 1.5s ease-out;
}

#loader {
  margin-right: .6em;
  margin-bottom: 2px;
}

@media (max-width: 700px) {
  .notification {
    width: 70vw;
  }
  .columnContainer {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
  #contactMeHeaderImage {
    max-height: 25vh;
  }
}