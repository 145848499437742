body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: medium !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
    url(./fonts/DancingScript/DancingScript-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"),
    url(./fonts/Merriweather/Merriweather-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Playfair_Display_Regular";
  src: local("Playfair_Display"),
    url(./fonts/Playfair_Display/PlayfairDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Playfair_Display_Italic";
  src: local("Playfair_Display"),
    url(./fonts/Playfair_Display/PlayfairDisplay-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Playfair_Display_Semibold";
  src: local("Playfair_Display"),
    url(./fonts/Playfair_Display/PlayfairDisplay-SemiBold.ttf) format("truetype");
}
