
.blogPostTile {
  padding: 2rem;
}

.blogPostTile:nth-child(odd) {
  background-color: #FFF
}
.blogPostTile:nth-child(even) {
  background-color: #e3e5ec
}

.blogPostTile .centeredChunk{
  margin: auto;
  max-width: 70rem;
  display: flex;
  justify-content: space-between;
}

.blogPostTile .date{
  width: 8rem;
  flex-shrink: 0;
}

.blogPostTile img{
  height:16rem;
}

.blogPostTile .textBlock{
  padding: 0 2rem;
}