
#homePage h1 {
  font-weight: normal;
  font-size: 3.5em;
  color: #394d7a;
  font-family: "Playfair_Display_Regular";
  margin-bottom: 32px;
}

@media (max-width: 500px) {
  #homePage h1 {
    margin-bottom: 8px;
  }
}

#homePage h2 {
  color: #6f7491;
  font-family: "Playfair_Display_Italic";
  font-size: 1.75em;
  font-weight: normal;
}

#homePage  .divider {
  width: 100%;
  height: 0;
  border-top: black solid 1px;
}

#homePage i.heart.icon {
  font-size: .8em;
}

#homePage .header {
  padding-top: 50px;
  text-align: center;
}

